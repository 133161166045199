<template>
  <b-modal
    id="modal-activity-form"
    title="Thêm mới hoạt động"
    centered
    hide-footer
    size="md"
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <div>
          <basic-select
            label="Loại hoạt động"
            placeholder="--- Chọn loại hoạt động ---"
            required
            changeValueByObject
            :options="activityTypes"
            :value.sync="form.type"
            track-by="value"
            value-label="text"
            :allow-empty="false"
            name="type"
            v-validate="'required'"
            :state="validateState('type')"
            :invalidFeedback="errors.first('type')"
            data-vv-as="Loại hoạt động"
            @update:value="selectType"
          />
          <basic-input
            v-if="isFreeActivity"
            label="Tên hoạt động"
            placeholder="Nhập tên hoạt động"
            required
            :value.sync="form.name"
            maxlength="24"
            name="name"
            v-validate="'required'"
            :state="validateState('name')"
            :invalidFeedback="errors.first('name')"
            data-vv-as="Tên hoạt động"
          />
          <div v-if="isFreeActivity">
            <label for=""
              >Thực hiện trong bao lâu <span class="text-danger">*</span></label
            >
            <b-row>
              <b-col>
                <basic-select
                  changeValueByObject
                  :options="executionTimes"
                  track-by="value"
                  value-label="text"
                  placeholder="--- Thời gian ---"
                  :value.sync="form.executionTime"
                  name="time"
                  v-validate="'required'"
                  :state="validateState('time')"
                  :invalidFeedback="errors.first('time')"
                  data-vv-as="Thời gian"
                  @update:value="$validator.reset({ name: 'time' })"
                />
              </b-col>
              <b-col>
                <basic-select
                  changeValueByObject
                  :options="executionTimeUnits"
                  track-by="value"
                  value-label="text"
                  placeholder="--- Đơn vị ---"
                  :value.sync="form.executionTimeUnit"
                  name="unit"
                  v-validate="'required'"
                  :state="validateState('unit')"
                  :invalidFeedback="errors.first('unit')"
                  data-vv-as="Đơn vị"
                  @update:value="$validator.reset({ name: 'unit' })"
                />
              </b-col>
            </b-row>
          </div>
          <basic-select
            v-if="isKpiActivity"
            label="Số lần thực hiện trong ngày"
            changeValueByObject
            :options="executionDays"
            track-by="value"
            value-label="text"
            placeholder="--- Chọn số thực hiện trong ngày ---"
            :value.sync="form.numberActivity"
            name="numberActivity"
            v-validate="'required'"
            :state="validateState('numberActivity')"
            :invalidFeedback="errors.first('numberActivity')"
            data-vv-as="Số lần thực hiện trong ngày"
            required
            @update:value="$validator.reset({ name: 'numberActivity' })"
          />
          <template v-if="isKpiActivity || isFreeActivity">
            <basic-select
              label="Tuỳ chọn lặp lại"
              changeValueByObject
              :options="repeatTypes"
              track-by="value"
              value-label="text"
              placeholder="--- chọn tuỳ chọn lặp lại ---"
              :value.sync="form.repeat"
              name="repeat"
              v-validate="'required'"
              :state="validateState('repeat')"
              :invalidFeedback="errors.first('repeat')"
              data-vv-as="Tùy chọn lặp lại"
              required
              @update:value="$validator.reset({ name: 'repeat' })"
            />
          </template>
          <template v-if="(isFreeActivity || isKpiActivity) && isRepeat">
            <b-form-group
              v-if="isWeeklyRepeat"
              label="Lặp lại hàng tuần"
              :id="`repeatWeek-group`"
              label-for="repeatWeek-ID"
              :invalid-feedback="errors.first('repeatWeek')"
              :state="validateState('repeatWeek')"
              class="required"
            >
              <div class="d-flex">
                <v-chip-group
                  v-model="form.daysInWeek"
                  v-validate="{
                    required: true,
                  }"
                  required
                  name="daysInWeek"
                  data-vv-as="Setting lặp lại theo tuần"
                  multiple
                  class="calendar-schedule-loop-modal__chip-group"
                >
                  <v-chip
                    v-for="(options, index) in weekdayOptions"
                    :key="index"
                  >
                    {{ options.text }}
                  </v-chip>
                </v-chip-group>
              </div>
              <div class="invalid-feedback d-block mb-4">
                {{ errors.first('daysInWeek') }}
              </div>
            </b-form-group>
            <b-form-group
              label="Ngày kết thúc"
              :id="`endDate-group`"
              label-for="endDate-ID"
              :invalid-feedback="errors.first('endDate')"
              :state="validateState('endDate')"
              class="required"
            >
              <date-picker
                id="endDate-ID"
                placeholder="chọn ngày kết thúc"
                :disabled-date="disabledBeforeToday"
                v-model="form.endDate"
                :format="dateFomartion"
                input-class="form-control"
                type="date"
                value-type="format"
                name="endDate"
                v-validate="'required'"
                data-vv-as="Tùy chọn lặp lại"
              />
            </b-form-group>
          </template>
          <basic-input
            v-if="isActivity"
            label="Số phút hoạt động theo ngày"
            placeholder="Nhập số phút hoạt động theo ngày"
            input-number
            :value.sync="form.activeMinutes"
          />
          <template v-if="isSurvey">
            <b-form-group
              label="Ngày diễn ra hoạt động"
              :id="`activityDate-group`"
              label-for="activityDate-ID"
              :invalid-feedback="errors.first('activityDate')"
              :state="validateState('activityDate')"
              class="required"
            >
              <date-picker
                id="activityDate-ID"
                required
                placeholder="chọn ngày diễn ra hoạt động"
                :disabled-date="disabledBeforeToday"
                v-model="form.activityDate"
                :format="dateFomartion"
                input-class="form-control"
                type="date"
                value-type="format"
                name="activityDate"
                v-validate="'required'"
                data-vv-as="Ngày diễn ra hoạt động"
              />
            </b-form-group>
            <basic-select
              label="Chọn khảo sát"
              changeValueByObject
              :options="surveyList"
              placeholder="--- Chọn khảo sát ---"
              :value.sync="form.survey"
              name="survey"
              v-validate="'required'"
              :state="validateState('survey')"
              :invalidFeedback="errors.first('survey')"
              data-vv-as="Khảo sát"
            />
          </template>
        </div>
        <div class="d-flex justify-content-end align-items-center mt-4">
          <b-button class="btn mr-2" type="button" @click="onClickCancleBtn">
            Hủy
          </b-button>
          <b-button class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  TARGET_REPEAT_TYPES,
  TARGET_TYPES,
} from '../../../../core/plugins/constants';
import DatePicker from 'vue2-datepicker';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('userDashboard');
export default {
  name: 'Activity',
  components: {
    DatePicker,
  },
  data() {
    return {
      dateFomartion: 'DD/MM/YYYY',
      loading: false,
      activityTypes: [
        {
          text: 'Đo huyết áp',
          value: TARGET_TYPES.BLOOD_PRESSURE,
        },
        {
          text: 'Đo cân nặng',
          value: TARGET_TYPES.WEIGHT,
        },
        {
          text: 'Nhập cảm xúc',
          value: TARGET_TYPES.EMOTION,
        },
        {
          text: 'Cập nhật bữa ăn',
          value: TARGET_TYPES.FOOD,
        },
        {
          text: 'Vận động',
          value: TARGET_TYPES.EXERCISE,
        },
        {
          text: 'Khảo sát',
          value: TARGET_TYPES.SURVEY,
        },
        {
          text: 'Hoạt động tự do',
          value: TARGET_TYPES.FREE,
        },
      ],
      form: {
        type: null,
        repeat: null,
        endDate: null,
        executionTime: null,
        executionTimeUnit: null,
        daysInWeek: [],
        activeMinutes: null,
        survey: null,
        activityDate: null,
        numberActivity: null,
        name: null,
      },
      weekdayOptions: [
        { text: 'T2', value: 1 },
        { text: 'T3', value: 2 },
        { text: 'T4', value: 3 },
        { text: 'T5', value: 4 },
        { text: 'T6', value: 5 },
        { text: 'T7', value: 6 },
        { text: 'CN', value: 0 },
      ],
      executionTimes: Array.from({ length: 200 }, (_, i) => {
        return {
          text: `${i + 1}`,
          value: i + 1,
        };
      }),
      executionDays: Array.from({ length: 20 }, (_, i) => {
        return {
          text: `${i + 1}`,
          value: i + 1,
        };
      }),
      executionTimeUnits: [
        {
          text: 'Lần',
          value: 1,
        },
        {
          text: 'Phút',
          value: 0,
        },
      ],
      repeatTypes: [
        {
          text: 'Hàng ngày',
          value: TARGET_REPEAT_TYPES.DAILY,
        },
        {
          text: 'Hàng tuần',
          value: TARGET_REPEAT_TYPES.WEEKLY,
        },
        {
          text: 'Không lặp lại',
          value: TARGET_REPEAT_TYPES.NO_REPEAT,
        },
      ],
      surveyList: [],
      appointmentDate: this.$moment(new Date()).format('DD/MM/YYYY'),
    };
  },
  computed: {
    ...mapState(['customerInfo']),
    isActivity() {
      return this.form.type?.value === TARGET_TYPES.EXERCISE;
    },
    isSurvey() {
      return this.form.type?.value === TARGET_TYPES.SURVEY;
    },
    isFreeActivity() {
      return this.form.type?.value === TARGET_TYPES.FREE;
    },
    isKpiActivity() {
      return [
        TARGET_TYPES.BLOOD_PRESSURE,
        TARGET_TYPES.WEIGHT,
        TARGET_TYPES.EMOTION,
        TARGET_TYPES.FOOD,
      ].includes(this.form.type?.value);
    },
    isRepeat() {
      if (!this.form.repeat) return false;
      return this.form.repeat?.value !== TARGET_REPEAT_TYPES.NO_REPEAT;
    },
    isWeeklyRepeat() {
      return this.form.repeat?.value === TARGET_REPEAT_TYPES.WEEKLY;
    },
    patientId() {
      return this.$route.params.id;
    },
  },
  methods: {
    resetModal() {
      this.form = {
        type: null,
        repeat: null,
        endDate: null,
        executionTime: null,
        executionTimeUnit: null,
        daysInWeek: [],
        activeMinutes: null,
        survey: null,
        activityDate: null,
        numberActivity: null,
        name: null,
      };
      this.$emit('reset');
    },
    onClickCancleBtn() {
      this.$bvModal.hide('modal-activity-form');
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;

        this.handleSubmit();
      });
    },
    handleSubmit() {
      let payload = {
        patientId: this.patientId,
        accountId: this.customerInfo.accountId,
        name: this.form.name,
        appointmentDate: this.convertDateToTimestamp(this.appointmentDate),
      };

      if (this.isFreeActivity) {
        payload = {
          ...payload,
          executeDayTimes: this.form.executionTime.value,
          executeType: this.form.executionTimeUnit.value,
          repeatTime: 0,
          repeatType: this.form.repeat.value,
          endDate: this.convertDateToTimestamp(this.form.endDate),
        };
        if (this.isWeeklyRepeat) {
          payload['targetSchedulerWeeks'] = this.form.daysInWeek.map((el) => ({
            dayInWeek: el,
          }));
        }

        return this.submitFreeActivity(this.removeEmpty(payload));
      }

      if (this.isKpiActivity) {
        payload = {
          ...payload,
          executeDayTimes: this.form.numberActivity.value,
          executeType: 1,
          repeatTime: 0,
          repeatType: this.form.repeat.value,
          endDate: this.convertDateToTimestamp(this.form.endDate),
          type: this.form.type.value,
        };

        if (this.isWeeklyRepeat) {
          payload['targetSchedulerWeeks'] = this.form.daysInWeek.map((el) => ({
            dayInWeek: el,
          }));
        }

        return this.submitKpiActivity(this.removeEmpty(payload));
      }

      if (this.isActivity) {
        payload = {
          ...payload,
          executeDayTimes: Number(this.form.activeMinutes),
        };

        return this.submitActivity(this.removeEmpty(payload));
      }

      payload = {
        ...payload,
        appointmentDate: this.convertDateToTimestamp(this.form.activityDate),
        surveyId: this.form.survey.id,
      };
      return this.submitSurveyActivity(this.removeEmpty(payload));
    },
    async submitFreeActivity(payload) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.post(
          '/UserDashboard/Target-Custom',
          payload,
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.showToastrMessage('Thêm hoạt động mới thành công', 'success');
        this.onClickCancleBtn();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    async submitKpiActivity(payload) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.post(
          '/UserDashboard/Target-KPI',
          payload,
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.showToastrMessage('Thêm hoạt động mới thành công', 'success');
        this.onClickCancleBtn();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    async submitActivity(payload) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.post(
          '/UserDashboard/Target-Excercise',
          payload,
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.showToastrMessage('Thêm hoạt động mới thành công', 'success');

        this.onClickCancleBtn();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    async submitSurveyActivity(payload) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.post(
          '/UserDashboard/Target-Survey',
          payload,
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.showToastrMessage('Thêm hoạt động mới thành công', 'success');

        this.onClickCancleBtn();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
    selectType(selected) {
      if (selected.value === TARGET_TYPES.SURVEY) {
        this.getSurveys();
      }
      this.$validator.reset({ name: 'type' });
    },
    async getSurveys() {
      try {
        this.loading = true;
        const { meta, data } = await this.$api.get(
          '/Survey/GetSurveyForTarget',
        );

        if (!meta.success) return;

        this.surveyList = data.map((el) => ({ id: el.id, name: el.name }));
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.calendar-schedule-loop-modal__chip-group {
  .v-chip {
    border-radius: 50%;
    height: 40px;
    font-size: 13px;
    background-color: #fff;
    border: 1px solid #dddddd;
  }

  .v-chip--active {
    background-color: #008479 !important;
    color: #fff;
  }
}
</style>
